.wrapper {
    width: 100vw;
    height: 100%;
    /* background-color: var(--contact-bg); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* overflow-x: hidden; */
}

/* .wrapper div {
    display: block;
} */

.form {
    /* display: flex; */
    width: 100%;
    max-width: 600px;
    padding: 30px;
}

.form h1 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 6rem;
}

.wrapper p {
    text-align: center;
    margin-bottom: 1rem;
}

.form label {
    display: none;
}

.topRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.form input,
.form textarea {
    width: 100%;
    border: none;
    border-radius: 30px;
    padding: 15px;
    background-color: var(--modal-bg);
    margin-bottom: 1rem;
    color: var(--white);
    font-family: inherit;
}

.form .button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.form button {
    border: none;
    border-radius: 30px;
    background-color: var(--yellow);
    color: var(--black-btn);
    padding: 12px 34px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
}

input.halfWidth {
    max-width: 48%;
    flex: 1 1 45%;
}

section.social {
    margin-top: 3rem;
}
.links {
    padding: 15px;
    width: 600px;
    display: flex;
    justify-content: center;
}

.links a {
    display: flex;
    align-items: center;
    width: 100px;
    margin: 0 10px;
}

.links svg {
    margin-right: 5px;
}

.message {
    color: red;
    font-size: 1.5rem;
}

@media only screen and (max-width: 650px) {
    .form h1 {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 550px) {
    .form h1 {
        font-size: 1.5rem;
    }

    .topRow {
        flex-direction: column;
    }

    input.halfWidth {
        max-width: 100%;
    }
    .form button {
        width: 100%;
    }

    .links {
        flex-direction: column;
        align-items: center;
        width: auto;
    }
    .links a {
        margin: 10px 0;
    }
}
