
.wrapper a{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-bottom: 0.5rem;
    
}

.wrapper p.text {
    padding-left: 10px;
}


@media only screen and (max-width: 600px) {
    .wrapper {
        padding: 20px;
    }
    .wrapper a {
        justify-content: center;
        margin-bottom: 1rem;
    }   
}