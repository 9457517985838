.footer {
    height: 30px;
    /* flex: 0 0 30px; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--header-bg);
    margin: 0px;
    border-radius: 6px;
    color: var(--white);
}

.footer>a {
    text-decoration: none;
    color: inherit;
    padding: 0 5px;
}