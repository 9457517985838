.header {
    height: 50px;
    /* flex: 0 0 50px; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--header-bg);
    border-radius: 6px;
    margin: 0px;
    color: var(--white);
}

.header>nav>ul {
    display: flex;
    align-items: center;
}

.header>nav>ul>li {
    padding: 10px 15px;
}

.header a {
    cursor: pointer;
}

.sup {
    margin-left: 0.3rem;
    font-size: 0.6rem;
    vertical-align: super;
}


@media only screen and (max-width: 500px){
    .logo {
        display: none;
    }
}