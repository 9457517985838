.message {
    position: fixed;
    top: 6rem;
    left: 0;
    text-align: center;
    font-size: 2rem;
    /* width: 100%; */
    width: 12rem;
    background-color: red;
    color: var(--yellow);
    transform-origin: bottom left;
    transform: rotate(-45deg);
}

.message:hover {
    /* transform: scale(1.1) rotate(-45deg) translate(10%, 10%);  */
    background-color: var(--yellow);
    color: red;
}

@media only screen and (max-width: 650px) {
    .message {
        display: none;
    }
}