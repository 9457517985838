.wrapper {
    width: 80vw;
    max-width: 1050px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.wrapper h2 {
    align-self: flex-start;
    margin: 20px;
    font-size: 2rem;
    color: var(--yellow);
}

.app {
    width: 100%;
    /* border: 1px solid var(--white); */
    background-color: var(--modal-bg);
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
}

.header img {
    max-height: 150px;
}

.headerText {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.headerText h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.description {
    margin-top: 2rem;
}

.description p {
    line-height: 1.5rem;
}

.images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 680px) {
    .wrapper h2 {
        font-size: 1.5rem;
    }

    
    .headerText h3 {
        font-size: 1.3rem;
        line-height: 1.6rem;
    }
}

@media only screen and (max-width: 600px) {
    .header {
        flex-direction: column;
    }

    .header img {
        max-width: 150px;
        align-self: center;
        margin-bottom: 20px;
    }
    
    .headerText {
        margin-left: 0;
    }

    .headerText h3 {
        text-align: center;
    }

    .description {
        margin-bottom: 2rem;
    }
}