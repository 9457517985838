.bigWrapper {
    width: 100vw;
    height: 100%;
    /* display: flex;
    flex-direction: column; */
}

.wrapper {
    /* flex: 1; */
    width: 100vw;
    /* height: calc(100vh - 83px); */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px;
}

.tagline {
    position: absolute;
    top: 180px;
    left: 80px;
    color: yellow;
    width: 60%;
    font-size: 4rem;
    text-align: center;
}

img.heroImg {
    width: 60%;
    padding: 25px;
}

section.intro {
    font-size: 2rem;
    line-height: 2.5rem;
    padding: 20px;
}

p.main {
    font-size: 2.5rem;
    line-height: 3rem;
    text-decoration: underline;
}

p.main span {
    font-weight: 600;
}


@media only screen and (max-width: 1450px) {
    .tagline {
        font-size: 3.5rem;
    }

}

@media only screen and (max-width: 1200px) {
    section.intro {
        font-size: 1.5rem;
    }

    p.main {
        font-size: 2rem;
    }

    .tagline {
        width: 80%;
        left: 10%;
        margin-right: 20px;

    }
}

@media only screen and (max-width: 930px) {
    .tagline {
        top: 100px;
    }

    .wrapper {
        flex-direction: column;
    }

    section.intro {
        text-align: center;
    }

    img.heroImg {
        order: -1;
    }
}

@media only screen and (max-width: 768px) {
    .tagline {
        font-size: 3rem;
        line-height: 3.5rem;
    }
}

@media only screen and (max-width: 650px) {
    .tagline {
        font-size: 2.5rem;
    }
}

@media only screen and (max-width: 500px) {
    .tagline {
        font-size: 2rem;
    }

    section.intro {
        font-size: 1rem;
    }

    p.main {
        font-size: 1.8rem;
    }

    img.heroImg {
        width: 80%;
    }
}

@media only screen and (max-width: 440px) {
    .tagline {
        line-height: normal;
    }

    p.main {
        font-size: 1.5rem;
        line-height: 2.2rem;
    }
}

@media only screen and (max-height: 1000px) {
    /* .wrapper {
        padding-top: 200px;
    } */
}

@media only screen and (max-height: 930px) {
    .wrapper {
        padding-top: 250px;
    }
}