.wrapper {
    width: 100%;
    height: 100%;
    /* height: calc(100vh - 83px); */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px;
}

.wrapper article {
    margin: 2rem;
}
.wrapper h1 {
    font-size: 2.5rem;
    /* width: 100%; */
}

.wrapper p {
    /* text-indent: 30px; */
    text-align: justify;
    font-size: 1.5rem;
    margin: 20px 0;
    line-height: 2rem;
}

.wrapper h2 {
    font-size: 2rem;
}

.wrapper button {
    border: none;
    border-radius: 30px;
    background-color: var(--yellow);
    color: var(--black-btn);
    padding: 12px 34px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    margin: 0 0 50px 0;
}

.toRight {
    float: right;
}

.toLeft {
    float: left;
}

.small_pic {
    composes: toLeft;
    width: 400px;
    max-width: 90%;
    margin-right: 15px;
    opacity: 0.8;

}

.pic {
    max-width: 80%;
}

@media only screen and (max-width: 500px) {
    .wrapper {
        padding: 80px 10px;
    }
    .wrapper article {
        margin: 0;
    }
}