.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    margin: 0;
    /* overflow-y: hidden; */
}

.content {
    display: flex;
    flex: 1;
    overflow: hidden;
}

.content main {
    color: #E1E6ED;
}


@media all and (max-width: 700px) {}