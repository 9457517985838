.wrapper {
    width: 100vw;
    height: 100%;
    /* height: calc(100vh - 83px); */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
}
.wrapper h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    /* width: 100%; */
    text-align: center;
}

@media only screen and (max-width: 680px) {
    .wrapper h1 {
        font-size: 2rem;
        line-height: 2.5rem;
    }
}